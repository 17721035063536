import "./components/_hot-swiper.scss";

import $ from "jquery";
import modules from "ym";
import "./modules/swiper";
import listenToMediaQuery from "./lib/listen-to-media-query";

let mobileMediaQuery = "(min-width: 576px)";
let hoveredElement = null;
let isMobile = true;

listenToMediaQuery(mobileMediaQuery, (isDesktop) => {
  isMobile = !isDesktop;
});

const autoplayMobile = false;
const autoplayDesktop = {
  delay: 4000,
  reverseDirection: true,
  disableOnInteraction: false,
};

const getOrCreateSwiper = (element) => {
  return new Promise((resolve, reject) => {
    modules.require(["swiper"], (Swiper) => {
      if (element.swiper) {
        resolve(element.swiper);
      } else {
        const swiper = new Swiper(element, {
          allowTouchMove: false,
          roundLengths: true,
          slidesPerView: 6,
          spaceBetween: 0,
          loop: true,
          speed: isMobile ? 400 : 1500,
          autoplay: isMobile ? autoplayMobile : autoplayDesktop,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },

          // Enable lazy loading
          preloadImages: false,
          watchSlidesVisibility: true,
          lazy: { loadOnTransitionStart: true },

          breakpoints: {
            425: {
              speed: 400,
              slidesPerView: 1,
              allowTouchMove: true,
            },
            576: {
              speed: 1500,
              slidesPerView: 2,
              allowTouchMove: false,
            },
            768: {
              speed: 1500,
              slidesPerView: 3,
              allowTouchMove: false,
            },
            992: {
              speed: 1500,
              slidesPerView: 4,
              allowTouchMove: false,
            },
            1200: {
              speed: 1500,
              slidesPerView: 5,
              allowTouchMove: false,
            },
          },
        });

        swiper.on("slideChangeTransitionStart", () => {
          // console.log('slideChangeTransitionStart', swiper.activeIndex);
        });

        swiper.on("slideChangeTransitionEnd", () => {
          // console.log('slideChangeTransitionEnd', swiper.activeIndex);
        });

        swiper.on("slideChange", (data) => {
          // const slide = this.slides[this.activeIndex];
          // console.log('slideChange', swiper.activeIndex);
        });

        resolve(swiper);
      }
    });
  });
};

$(".hot-swiper").each((i, containerElement) => {
  modules.require(["swiper"], () => {});

  const container = $(containerElement);
  const swiperContainer = container.find(".swiper-container");

  const popup = $('<div class="hot-swiper__popup" />');

  let isLoading = false;

  listenToMediaQuery(mobileMediaQuery, (isDesktop) => {
    getOrCreateSwiper(swiperContainer.get(0)).then((swiper) => {
      swiper.params.speed = !isDesktop ? 400 : 1500;
      // swiper.params.autoplay = !isDesktop ? autoplayMobile : autoplayDesktop;

      if (!isDesktop) {
        swiper.autoplay.stop();
      }

      if (isDesktop && !hoveredElement) {
        swiper.autoplay.start();
      }
    });
  });

  container.on("mouseenter mousemove", ".hot-swiper__popup", () => {
    getOrCreateSwiper(swiperContainer.get(0)).then((swiper) => {
      swiper.autoplay.stop();
    });
  });

  container.on("mouseleave", ".hot-swiper__popup", () => {
    popup.addClass("hidden");
    hoveredElement = null;

    getOrCreateSwiper(swiperContainer.get(0)).then((swiper) => {
      if (!isMobile && !hoveredElement) {
        swiper.autoplay.start();
      }
    });
  });

  container.on("mouseleave", ".swiper-slide .photo img", (e) => {
    getOrCreateSwiper(swiperContainer.get(0)).then((swiper) => {
      if (!isMobile && !hoveredElement) {
        swiper.autoplay.start();
      }
    });
  });

  container.on("mouseenter mousemove", ".swiper-slide .photo img", (e) => {
    const image = $(e.target);
    const target = image.closest(".swiper-slide");

    getOrCreateSwiper(swiperContainer.get(0)).then((swiper) => {
      if (!isMobile && !swiper.animating && hoveredElement !== target.get(0)) {
        swiper.autoplay.stop();

        hoveredElement = target.get(0);

        popup.html(target.find(".popup-content").html());
        popup.removeClass("hidden");
        popup.appendTo(container);
        popup.css({ width: 40 + image.width() });
        popup.position({
          collision: "none",
          my: "left top",
          at: "left-20 top-26",
          of: image,
        });
      }
    });
  });

  const load = () => {
    if (isLoading) {
      return false;
    }

    const url = container.attr("data-url");

    container.toggleClass("hot-swiper--loading", (isLoading = true));

    $.ajax({ url })
      .done((data) => {
        modules.require(["swiper"], () => {
          const items = data.items || [];

          container.toggleClass("hot-swiper--loading", (isLoading = false));
          container.toggleClass("hot-swiper--empty", !items.length);

          getOrCreateSwiper(swiperContainer.get(0)).then((swiper) => {
            swiper.removeAllSlides();
            swiper.appendSlide(items);
            swiper.update();
            swiper.lazy.load();

            if (!isMobile && !hoveredElement) {
              swiper.autoplay.start();
            }
          });
        });
      })
      .fail(() => {
        container.toggleClass("hot-swiper--loading", (isLoading = false));
      });

    return true;
  };

  container.on("click", ".hot-swiper__filter-item[data-href]", (e) => {
    const filterLink = $(e.target);
    filterLink.toggleClass("link-orange", true).toggleClass("link-blue", false);
    filterLink
      .siblings(".hot-swiper__filter-item[data-href]")
      .toggleClass("link-orange", false)
      .toggleClass("link-blue", true);
    container.attr("data-url", filterLink.attr("data-href"));
    load();
  });

  load();
});
