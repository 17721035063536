export default !window.matchMedia
  ? (mediaQuery, listener) => {
      listener(null);
    }
  : (mediaQuery, listener) => {
      const mq = window.matchMedia(mediaQuery);
      listener(mq.matches);

      if (mq.addEventListener) {
        mq.addEventListener("change", (e) => {
          listener(e.matches);
        });
      } else if (mq.addListener) {
        mq.addListener((e) => {
          listener(e.matches);
        });
      }
    };
